<div>
  @if (loading$()) {
    <div [style.padding.px]="20">
      <kdl-loading-indicator></kdl-loading-indicator>
    </div>
  } @else {
    <span matDialogTitle>{{ 'api_error_conflict_dialog_title' | ni18n }}</span>

    <div [style.padding]="'0px 20px 20px 20px'">
      <p>{{ 'api_error_conflict_dialog_body' | ni18n }}</p>
      <p>{{ 'api_error_conflict_dialog_body_choose' | ni18n }}</p>
      <ul>
        <li>
          {{ 'api_error_conflict_dialog_body_option_1_1' | ni18n }}
          <p>
            <em>{{ 'api_error_conflict_dialog_body_option_1_2' | ni18n }}</em>
          </p>
        </li>
        <li>
          {{ 'api_error_conflict_dialog_body_option_2_1' | ni18n }}
          <p>
            <em>{{ 'api_error_conflict_dialog_body_option_2_2' | ni18n }}</em>
          </p>
        </li>
      </ul>
      <p>
        {{
          'api_error_conflict_dialog_last_update' |
            ni18n:'d' :
            newerProject.modifiedBy :
            (newerProject.timeStamp | date: 'mediumTime')
        }}
      </p>
    </div>
    <mat-dialog-actions>
      <button
        mat-raised-button
        color="warn"
        (click)="forceUpdate()"
      >
        {{ 'api_error_conflict_dialog_force_update' | ni18n }}
      </button>

      <button
        mat-raised-button
        color="primary"
        (click)="window.location.reload()"
      >
        {{ 'api_error_conflict_dialog_reload_page' | ni18n }}
      </button>
    </mat-dialog-actions>
  }
</div>
