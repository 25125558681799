<mat-dialog-content>
  <span matDialogTitle>{{ 'api_error_conflict_dialog_title' | ni18n }}</span>

  <div [style.padding]="'0px 20px 20px 20px'">
    <p>{{ 'api_error_conflict_dialog_others_body' | ni18n }}</p>

    <p>
      <span>{{ 'api_error_conflict_dialog_others_url' | ni18n }}</span>
      <i> {{ data.url }}</i>
    </p>

    <p>{{ 'api_error_conflict_dialog_others_contact' | ni18n }}</p>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button
    mat-raised-button
    color="accent"
    mat-dialog-close
  >{{ 'common_cancel' | ni18n }}
  </button>
</mat-dialog-actions>
