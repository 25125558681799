import {registerLocaleData} from '@angular/common'
import {provideHttpClient, withInterceptors} from '@angular/common/http'
import localeFrExtra from '@angular/common/locales/extra/fr'
import localeSvExtra from '@angular/common/locales/extra/sv'
import localeFr from '@angular/common/locales/fr'
import localeSv from '@angular/common/locales/sv'
import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core'
import {ReactiveFormsModule} from '@angular/forms'
import {
  MAT_LUXON_DATE_ADAPTER_OPTIONS,
  MAT_LUXON_DATE_FORMATS
} from '@angular/material-luxon-adapter'
import {MatAutocompleteModule} from '@angular/material/autocomplete'
import {MatButtonModule} from '@angular/material/button'
import {MAT_DATE_FORMATS} from '@angular/material/core'
import {MatDatepickerModule} from '@angular/material/datepicker'
import {MatDialogModule} from '@angular/material/dialog'
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field'
import {MatInputModule} from '@angular/material/input'
import {BrowserModule} from '@angular/platform-browser'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {merge} from 'rxjs'

import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'
import {authInterceptor} from './application/auth-interceptor'

import {GlobalErrorHandler} from './application/error-handler'
import {I18N, i18nCoreFactory} from './application/i18n.provider'
import {LOCAL_STORAGE_PROVIDERS} from './application/localstorage.provider'
import {responseInterceptor} from './application/response-interceptor'
import {WINDOW_PROVIDERS} from './application/window.provider'
import {KdlCommonModule} from './common/common.module'
import {I18nService} from './i18n/service/i18n.service'
import {AuthService} from './services/auth.service'
import {BootstrapService} from './services/bootstrap.service'
import {MAT_EXPANSION_PANEL_DEFAULT_OPTIONS} from '@angular/material/expansion'

registerLocaleData(localeFr, 'fr', localeFrExtra)
registerLocaleData(localeSv, 'sv', localeSvExtra)

const bootstrapFactory = (as: AuthService) => () => as.checkLogin()

@NgModule({
  declarations: [
    AppComponent
  ],
  bootstrap: [AppComponent], imports: [BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    // Importing this here to be able to inject it in Dialog
    // See if it can be removed
    ReactiveFormsModule,
    // ---
    KdlCommonModule,
    // Must import this here for the OpenProjectService and Comment
    // Service that opens the dialog
    MatDialogModule,
    MatAutocompleteModule,
    MatInputModule,
    MatButtonModule,
    MatDatepickerModule], providers: [
    AuthService,
    {
      // This is nice magic to make sure we load any existing tokens.
      // We return an observable, the "angulars" will subscribe to this
      // and when it completes, it will continue.
      // Injecting the I18nService to bootstrap translations.
      provide: APP_INITIALIZER,
      useFactory: bootstrapFactory, //(bs: BootstrapService, as: AuthService) => () => as.checkLogin(),
      deps: [AuthService, I18nService, BootstrapService],
      multi: true
    },
    WINDOW_PROVIDERS,
    LOCAL_STORAGE_PROVIDERS,
    {
      provide: I18N,
      useFactory: () => merge(
        i18nCoreFactory('core'),
        i18nCoreFactory('dialogs'),
        i18nCoreFactory('comments'),
        i18nCoreFactory('cabinet'),
        i18nCoreFactory('project-viewer'),
        i18nCoreFactory('project-pricing'))
    },
    {provide: LOCALE_ID, useValue: 'sv-SE'},
    {provide: ErrorHandler, useClass: GlobalErrorHandler},
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {
        color: 'accent', appearance: 'fill', floatLabel: 'always'
      }
    },
    {
      provide: MAT_EXPANSION_PANEL_DEFAULT_OPTIONS,
      useValue: {expandedHeight: '75px', collapsedHeight: '75px'}
    },
    {
      provide: MAT_LUXON_DATE_ADAPTER_OPTIONS,
      useValue: {useUtc: true, firstDayOfWeek: 1}
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS},
    provideHttpClient(withInterceptors([authInterceptor, responseInterceptor]))
  ]
})
export class AppModule {
}
